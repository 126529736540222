@import 'bootstrap';
@import '~quill/dist/quill.snow.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,body, #root, .wrapper, .App {
  height: 100%;
}

.wrapper{
  display: flex;
}

.rotate-smooth{
  transition: transform 250ms ease;

  &.rotate-180{
    transform: rotateZ(180deg);
  }

}

//sidebar
.sidebar {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: #24283a;
  color: #fff;
  min-height: 100vh;
  width: 265px;
  transition: width 0.2s ease,min-width 0.2s ease;

  .sidebar-brand {
    color: #fff;
    background-color: #1d1f2b;
    height: 4.375rem;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 1.5rem 1rem;
    z-index: 1;

    .sidebar-brand-text{
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }

    .sidebar-brand-icon{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #007bff;
      width: 1.8em;
      height: 1.8em;
      border-radius: 0.4em;
    }
  }

  .nav-item{
    text-decoration: none;
    
    .nav-link{
      color: rgb(221, 221, 221);
      padding: 0.8rem 1rem;
      
      .nav-link-icon{
        display: inline;
        margin-right: 1em;
        color: #50647a;
      }
    }
  }

  .active{
    color: #fff;
    background-color: #384553;

    .nav-link .nav-link-icon{
      color: #fff;
    }
  }

  &.collapsed {
    width: 80px;

    .sidebar-brand-text, .nav-link-text{
      display: none;
    }

    .nav-item .nav-link .nav-link-icon{
      margin-right: 0;
      display: flex;
      justify-content: center;
      
      svg{
        height: 1.4em;
        width: 1.4em;
      }
    }
  }
}

.navbar{
  min-height: 4.375rem;
}

// .dashboard-widget{
//   box-shadow: 1px 2px 5px #1d1f2b3b;
// }

.shadow{
  box-shadow: 1px 2px 5px #1d1f2b3b;
}

//email body
.email-body img{
  max-width: 100% !important;
  width: 10px !important;
  height: 10px !important;
}